<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>文件上传</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="未上传文件" icon="md-document">
            <file-list
              :columnsFormatData="columnsFormatData"
              askData="fileForUpload"
              :refreshData="update_count"
              main_width="1260px"
              :dataId="projectId"
            ></file-list>
          </TabPane>
          <TabPane label="已上传文件" icon="md-done-all">
            <file-list
              :columnsFormatData="columnsFormatData"
              askData="fileUploadAlready"
              :refreshData="update_count"
              main_width="1260px"
              :dataId="projectId"
            ></file-list>
          </TabPane>
        </Tabs>
      </div>
      <Modal
        v-model="modalSwicth.candidate"
        :width="800"
        :mask-closable="false"
        @on-cancel="closeModal('candidate')"
      >
        <p slot="header" class="sc-header">
          <Icon type="ios-list-box" />
          <span>为（文件：{{formItem.file.name}}）设置候选上传人</span>
        </p>
        <i
          class="ivu-icon ivu-icon-ios-close"
          slot="close"
          @click="closeModal('candidate')"
        ></i>
        <div class="sc-content-wrapper">
          <div class="form-wrapper">
            <Form
              ref="formCandidate"
              :model="formItem"
              :rules="ruleValidate"
              :label-width="250"
            >
              <FormItem label="候选人姓名：" prop="name" style="width: 500px">
                <Input v-model="formItem.name" />
              </FormItem>
              <FormItem
                label="手机号："
                prop="account"
                style="width: 500px"
              >
                <Input v-model="formItem.account" placeholder />
              </FormItem>
            </Form>
          </div>
        </div>
        <div slot="footer">
          <Button
            @click="handleSubmit('formCandidate')"
            :loading="loadingStatus"
            size="large"
            >{{ loadingStatus ? "Uploading" : "确认提交" }}</Button
          >
          <Button @click="confirmBox(formItem.file)" size="large" style="margin-left: 8px"
            >清除候选人</Button
          >
          <Button
            size="large"
            @click="closeModal('candidate')"
            class="cancel-button"
            >取消</Button
          >
        </div>
      </Modal>
      
    </div>
  </div>
</template>
<script>
import fileList from "@/components/Common/FileList.vue";

let userToken = localStorage.getItem("current_user_token");
// let authData = JSON.parse(localStorage.getItem("authData"));
let theProject = JSON.parse(localStorage.getItem("project"));

function formItemFac() {
  return {
    id: 0,
    name: "",
    account: "",
    file:{}
  };
}

export default {
  name: "fileUpload",
  components: {
    fileList,
  },
  props: {},
  data() {
    return {
      update_count: 1,
      projectId:theProject.enid,
      columnsFormatData: [
        {
          title: "文件名称",
          width: 200,
          align: "center",
          key: "name",
        },
        {
          title: "涉及专业",
          key: "major",
          align: "center",
          width:100,
          render: (h, p) => {
            let majorList = [];
            for(let i of p.row.major.split(';')){
              for(let j of JSON.parse(i)){
                majorList.push(j.name);
              }
            }
            return h(
              "p",
              {},
              majorList.join(',')
            );
          }
        },
        {
          title: "涉及标准条目",
          key: "related_clause",
          align: "center",
          render: (h, p) => {
            let clauseList = JSON.parse(p.row.related_clause);
            return h(
              "div",
              clauseList.map(val=>{
                return h('p',{},
                  val.standard_no+'《'+val.standard_name+'》'+val.clause_no+'：'+val.content
                );
              })
            );
          }
        },
        {
          title: "候选上传人",
          key: "candidate_name",
          align: "center",
          width:120,
          render: (h, p) => {
            return h(
              "p",
              {
              },
              p.row.candidate_name?p.row.candidate_name:'暂无'
            );
          }
        },
        {
          title: "状态",
          key: "stat",
          align: "center",
          width:100
        },
        {
          title: "操作",
          align: "center",
          width: 300,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                    disabled:this.judge(params.row.stat)
                  },
                  style: { marginRight: "10px", color: this.judge(params.row.stat)?'grey':"green" },
                  on: {
                    click: () => {
                      window.open('/file/edit/'+params.row.enid,'_blank')
                    },
                  },
                },
                "上传"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                    disabled:this.judge(params.row.stat)
                  },
                  style: { marginRight: "10px", color:this.judge(params.row.stat)?'grey':"blue" },
                  on: {
                    click: () => {
                      this.formItem.name = params.row.candidate_name;
                      this.formItem.account = params.row.candidate;
                      this.formItem.file = params.row;
                      this.modalSwicth.candidate = true;
                    },
                  },
                },
                "设置候选上传人"
              ),
            ]);
          },
        },
      ],
      modalSwicth: {
        candidate: false,
      },
      formItem: formItemFac(),
      ruleValidate: {
        name: [
          {
            required: true,
            type: "string",
            message: "请输入姓名！",
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            type: "string",
            message: "请输入手机号！",
            trigger: "blur",
          },
        ],
      },
      loadingStatus: false,
    };
  },
  watch: {},
  computed: {},
  created() {
  },
  methods: {
    judge(stat){
      if(['待确认','已确认'].includes(stat)){
        return true;
      }else{
        return false;
      }

    },
    openModal(value) {
      this.modalSwicth[value] = true;
    },
    closeModal(value) {
      this.modalSwicth[value] = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除：",
        content: "<p>" + "文件：" + value.name + "的候选人"+value.candidate_name+"</p>",
        onOk: () => {
          this.recordFormatDel(value.id);
        },
        onCancel: () => {},
      });
    },
    recordFormatDel(id) {
      let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", "delCandidate");
      form.append("id", id);
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.$axios
        .post(this.$url + "/file_manage", form, config)
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
            this.modalSwicth.candidate = false;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    handleReset() {
      for (let item in this.formItem) {
        this.formItem[item] = "";
      }
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "addCandidate");
          form.append("info", JSON.stringify(this.formItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/file_manage", form, config)
            .then((response) => {
              this.handleReset();
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("候选人设置成功！");
                this.update_count++;
                this.modalSwicth.candidate = false;
              } else {
                this.modalSwicth.candidate = false;
                this.$Message.error('操作失败！'+response.data.errorInfo);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 40px;
  width: 1280px;
}

.editor-title,
.preview-title {
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.re-l {
  min-height: 750px;
  color: #000;
  border: #aaa solid 1px;
  box-shadow: 1px 2px 1px #aaa;
  text-align: center;
  position: relative;
  margin: 5px;
  padding: 0 10px;
}

.form-wrapper {
  text-align: left;
  padding: 80px 120px 80px 50px;
}
</style>