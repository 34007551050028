<template>
  <div>
    <div class="outer-wrapper" :style="{width:main_width}">
      <div class="m-option-wrapper">
        <div class="options-wrapper">
        <div class="criteria-wrapper">
          <label for="display-i" style="margin-right:30px;">文件名：<input class="text-input" type="text" name="display-i" v-model="optionData.name" placeholder="文件名称关键字" @keyup.enter="go_search"/></label>
          <label for="display-i" style="margin-right:30px;">标准名：<input class="text-input" type="text" name="display-i" v-model="optionData.standard_name" placeholder="标准名称关键字" @keyup.enter="go_search"/></label>
        </div>
        <div class="criteria-wrapper b-c">
          <span class="criteria-ele" v-if="validOptions.indexOf('major')!==-1">
            <select name="" id="" class="normal-select" v-model="optionData.major">
              <option :value="i.value" v-for="(i,index) in staticOptions.major" :key="index+'test'">{{i.text}}</option>
            </select>
          </span>
          <span class="criteria-ele" v-if="validOptions.indexOf('stat')!==-1">
            <select name="" id="" class="normal-select" v-model="optionData.stat">
              <option :value="i.value" v-for="(i,index) in staticOptions.stat" :key="index+'pay'">{{i.text}}</option>
            </select>
          </span>
          <span class="criteria-ele" v-if="validOptions.indexOf('order')!==-1">
            <select name="" id="" class="normal-select" v-model="optionData.order">
              <option :value="i.value" v-for="(i,index) in staticOptions.order" :key="index+'order'">{{i.text}}</option>
            </select>
          </span>
          <span class="criteria-ele" v-if="validOptions.indexOf('esc')!==-1">
            <select name="" id="" class="normal-select" v-model="optionData.esc">
              <option :value="i.value" v-for="(i,index) in staticOptions.esc" :key="index+'esc'">{{i.text}}</option>
            </select>
          </span>
        </div>
        </div>
        <div class="button-wrapper">
          <div class="keyword-input-wrapper">
            <label class="keyword-label">
              关键字：
              <input v-model="optionData.keyword" placeholder="请输入..." clearable class="text-input" style="width:300px;" @keyup.enter="go_search"/>
            </label>
          </div>
          <Button class="main-button" type="primary" icon="ios-search" @click="go_search">开始筛选</Button>
          <Button class="main-button re-button" type="primary" @click="reset_choices">重置选项</Button>
        </div>
      </div>
      <div class="table-wrapper" id="data-list">
            <list-with-page
              :Tpage="tableList.Tpage"
              :loading="tableList.loading"
              :columns_t="columnsFormatData"
              :totalItems="tableList.totalItems"
              :no_data_text="tableList.no_data_text"
              :tivdata="tableList.tableListData"
              @handleCurrentChange="handleCurrentChange"
              @handleSizeChange="handleSizeChange"
              v-model="selected"
            ></list-with-page>
      </div>
    </div>
  </div>
</template>
<script>
import listWithPage from "./ListWithPage.vue";


let userToken = localStorage.getItem("current_user_token");

const OPTIONDATA = {
  stat:[
    {text:'全部状态',value:''},
    {text:'未上传',value:'未上传'},
    {text:'待审核',value:'待审核'},
    {text:'待确认',value:'待确认'},
    {text:'已确认',value:'已确认'},
    {text:'已取消',value:'已取消'},
  ],
  major:[
    {text:'全部专业',value:''},
    {text:'机电',value:'机电'},
  ],
  order:[
    {text:'默认排序',value:''},
    {text:'上传日期',value:'upload'},
    {text:'审核日期',value:'verify'},
  ],
  esc:[
    {text:'排序方向',value:'DESC'},
    {text:'倒序',value:'DESC'},
    {text:'顺序',value:'ESC'}
  ]
}

function optionDataFac(){
  return {
    name:'',
    standard_name:'',
    stat:OPTIONDATA.stat[0].value,
    major:OPTIONDATA.major[0].value,
    keyword:'',
    order:'',
    esc:OPTIONDATA.esc[0].value
  };
}

export default {
  name: "fileList",
  components: {
    listWithPage,
  },
  props: {
    "columnsFormatData":{
        type:Array,
        required:true,
    },
    'validOptions':{
        type:Array,
        default:function(){
          return Object.keys(optionDataFac());
        }
    },
    "uri_name":{
        type:String,
        default:'/list_data'
    },
    "uri_option_name":{
        type:String,
        default:'/table_option'
    },
    "main_width":{
        type:String,
        default:''
    },
    'askData':{
        type:String,
        required:true
    },
    'dataId':{
        type:String,
        default:''
    },
    'refreshData':{
        type:Number,
        default:1
    },
    'selectedRow':{
      type:Array,
      default:function(){
        return [];
      }
    }
    },
  model:{
    prop:'selectedRow',
    event:'on-selection-change'
  },
  data() {
    return {
      tableList:{
            Tpage: {
                currentPage: 1,
                currentSize: 20
            },
            totalItems: 0,
            no_data_text: "",
            loading: true,
            columns_t: this.columns_data,
            tableListData: [],
        },
      staticOptions:OPTIONDATA,
      optionData:optionDataFac(),
      selected:this.selectedRow,
    };
  },
  computed: {
  },
  created() {
    // this.getData();
    this.getStaticOptions();
  },
  watch:{
      refreshData:{
        immediate: true,
        handler(){
          this.getData();
          this.selected = [];
        }
      },
      selected:function(){
        this.$emit('on-selection-change',this.selected);
      }
  },
  methods: {
    getStaticOptions(){
      this.$axios
        .post(this.$url + "/static_option", {
          userToken: userToken,
          ask_data: 'fileList',
        })
        .then(response => {
          if (response.data.stat === "ok") {
            this.staticOptions = response.data.res_record;
          }else{
            this.$Message.warning('请求选项数据失败！');
          }
        })
        .catch(error => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    getData() {
      this.tableList.loading = true;
      this.tableList.no_data_text = "数据加载中...";
      this.$axios
        .post(this.$url + this.uri_name, {
          page: this.tableList.Tpage,
          askData:this.askData,
          dataId:this.dataId,
          optionData:this.optionData,
          userToken: userToken
        })
        .then(
          response => {
            console.log(response.data);
            this.tableList.tableListData = response.data.res_record;
            this.tableList.totalItems = response.data.total_n;
            this.tableList.loading = false;
            this.tableList.no_data_text = "暂无数据";
          }
          
        );
    },
    reset_choices() {
      this.optionData = optionDataFac();
      this.go_search();
    },
    handleCurrentChange(value) {
      this.tableList.Tpage.currentPage = value;
      this.getData();
    },
    handleSizeChange(value) {
      this.tableList.Tpage.currentSize = value;
      this.tableList.Tpage.currentPage = 1;
      this.getData();
    },
    go_search() {
      this.tableList.Tpage.currentPage = 1;
      this.getData();
      this.selected = [];
    },
    goAnchor(selector) {
      this.$el.querySelector(selector).scrollIntoView();
    }
  },  
};
</script>
<style scoped>
.outer-wrapper {
  width: 1260px;
  margin: 0 auto;
}

.options-wrapper{
  padding-bottom: 20px;
}

.criteria-wrapper{
  margin:0px 20px 0px;
  padding: 12px 0;
  overflow: hidden;
  display: flex;
  display: -webkit-flex;
  flex-wrap:wrap;
  justify-content:flex-start;
}

.b-c{
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
  justify-content:flex-start;
}

.b-c .criteria-ele{
  margin-right:8px;
  margin-top:5px;
  margin-bottom:5px; 
}

.check-ele{
  position: relative;
  top:4px;
}

.normal-select{
  height:26px;
  min-width:90px;
  padding-left:5px;
  outline: none;
}

.text-input{
  border: none;
  border-bottom: 1px solid #888;
  outline: none;
  text-align: center;
  font-size:13px;
}

.text-input:focus{
  border-bottom: 1px solid #993333;
}

.date-input{
  outline: none;
  height:26px;
  position: relative;
  top:-1px;
  padding-left:5px; 
}

.check-box{
  position: relative;
  top:2px;
}

.m-option-wrapper {
  box-shadow: 2px 2px 1px #aaaaaa;
  border: 1px solid #e7e7e7;
  padding: 2px;
  overflow: hidden;
}

.button-wrapper {
  text-align: right;
  height: 50px;
}

.main-button {
  margin-right: 30px;
}

.main-button {
  background-color: #993333;
  color: #ddd;
  border: none;
}

.main-button:hover {
  background-color: #993333;
  border: none;
  color: #fff;
}

.re-button {
  margin-right: 6%;
}

.table-wrapper {
  margin-top: 10px;
  box-shadow: 2px 2px 1px #aaaaaa;
  border: 1px solid #e7e7e7;
  padding: 0 10px;
  overflow: hidden;
}

.keyword-input-wrapper {
  float: left;
  margin-left: 20px;
}


input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #aaa;
  font-size: 10px;
}

input:-moz-placeholder, textarea:-moz-placeholder {
  color: #aaa;
  font-size: 10px;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #aaa;
  font-size: 10px;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #aaa;
  font-size: 10px;
}
</style>